@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif;
  /* padding-top: 70px; */
}
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* Customize Data Table */

.mantine-Pagination-root .mantine-active[aria-current="page"] {
  background-color: #8f0004;
  font-weight: 700;
  border-color: #8f0004;
}
.reactour__popover {
  padding: 13px;
  border-radius: 10px;
}
.reactour__popover span{
  background-color: #8f0004 !important;
}

/* Customize Date Picker */

.wp-block-table{
  margin-block: 10px;
  padding: 7px;
}
.wp-block-table table{
  width: 100%;
  border-collapse: collapse;
}
.wp-block-table table td{
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #DDD;
  
}
.wp-block-table table tr:hover {background-color: #D6EEEE;}
.wp-block-table table tr:nth-child(even) {
  background-color: #f4f1fc;
}
.content-help-center p{
  margin-block: 10px;
}
.content-help-center ul{
  list-style: square;
  padding-inline-start: 20px;
  margin-block: 20px;
}
.content-help-center ul li{
  margin-block: 15px;
}
.wp-block-embed__wrapper, .wp-block-image {
  display: flex;
  justify-content: center;
}
.wp-block-embed__wrapper iframe, .wp-block-image img{
  border: 0;
  border-radius: 7px;
}
@media (max-width: 768px) {
  .wp-block-embed__wrapper iframe, .wp-block-image img{
    width: 100%;
  }
}
